/* requires:
polyfill.js
reveal.js
*/

// for active menu highlighting
(function () {
  const a = document.getElementById('nav').getElementsByTagName('a');
  // let loc;
  // if (window.location.href.substr(location.href.length - 1, 1) == '/') {
  //   loc = window.location.href + 'index.html';
  // } else {
  //   loc = window.location.href;
  // }
  let loc = window.location.href;
  for (var i = 0; i < a.length; i++) {
    if (a[i].href == loc) {
      a[i].classList.add('is-active');
    }
  }
})();

document
  .getElementById('nav')
  // .querySelector('ul')
  .insertAdjacentHTML(
    'beforebegin',
    "<button id='menutoggle' aria-label='Menu' role='button'><div class='hamburger-box'><div class='hamburger-inner'></div></div><span>Menu</span></button>"
  );
const menuToggle = document.getElementById('menutoggle');
const activeClass = 'is-active';

menuToggle.onclick = function (event) {
  menuToggle.classList.toggle(activeClass);
  // menuToggle.nextSibling.classList.toggle(activeClass);
  var el = document.querySelectorAll('#nav span.submenu, #nav a.submenu, #nav ul.submenu');
  var i;
  for (i = 0; i < el.length; i++) {
    el[i].classList.remove(activeClass);
  }
  event.preventDefault();
};

for (let submenuToggle of document.querySelectorAll('#nav span.submenu, #nav a.submenu')) {
  submenuToggle.addEventListener('click', function () {
    if (menuToggle.offsetWidth > 0 && menuToggle.offsetHeight > 0) {
      // if the #menuToggle is visible
      submenuToggle.classList.toggle(activeClass);
    }
  });
}

function hideMenu() {
  var el = document.querySelectorAll('#menutoggle, #menutoggle + ul, #nav span.submenu, #nav a.submenu, #nav ul.submenu');
  var i;
  for (i = 0; i < el.length; i++) {
    el[i].classList.remove(activeClass);
  }
}

document.addEventListener(
  'click',
  function (e) {
    if (menuToggle.offsetWidth > 0 && menuToggle.offsetHeight > 0) {
      // if the #menuToggle is visible
      var e = e ? e : window.event;
      var eventElement = e.target;
      if (!eventElement.closest('#nav') && !eventElement.closest('#menutoggle')) {
        //console.log(event_element.closest("#nav"));
        if (menuToggle.classList.contains(activeClass)) {
          hideMenu();
        }
      }
    }
  },
  false
);

var resizeTimer;
window.addEventListener(
  'resize',
  function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (menuToggle.offsetWidth <= 0 && menuToggle.offsetHeight <= 0) {
        // if the #menuToggle is hidden
        hideMenu();
      }
    }, 250);
  },
  false
);

// revealbar('#header-wrap', {
//   bottomOffset: 0,
//   onDetach: function () {
//     document.body.classList.add('header-fixed');
//   }.bind(this),
//   onAttach: function () {
//     document.body.classList.remove('header-fixed');
//   }.bind(this),
//   // onShow: function () {
//   //   window.revealBarIsVisible = !0;
//   // },
//   // onHide: function () {
//   //   window.revealBarIsVisible = !1;
//   // },
// });

// detecting scroll direction
// if scrolled up stop monitoring scroll direction
// if scrolled down monitor scroll direction
// function scrollDetect(offset = '100') {
//   const element = document.getElementById('header-wrap');
//   let direction;
//   let lastScroll = 0;

//   window.onscroll = function () {
//     let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

//     setTimeout(() => {
//       if (currentScroll > 0 && lastScroll <= currentScroll) {
//         lastScroll = currentScroll;
//         direction = 'down';
//         console.log(direction);
//       } else {
//         lastScroll = currentScroll;
//         direction = 'up';
//         console.log(direction);
//       }
//     }, 1000);

//     if (direction === 'up' && lastScroll - currentScroll >= offset) {
//       console.log('Show nav');
//       element.classList.add('is-fixed');
//     }
//     if (direction === 'down' && lastScroll - currentScroll <= offset) {
//       console.log('Hide nav');
//       element.classList.remove('is-fixed');
//     }
//   };
// }
// scrollDetect();
